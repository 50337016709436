import client from './client'

export default {
  create({
    optInConsentHintId,
    email,
    code,
    q,
    customTags,
    categoryTags,
    isActive,
    searchProfileName,
    campaignLayerName,
    registrationCampaign,
    sponsoredMailingOptInConsentHintId,
    returnTo,
    pageId
  }) {
    const body = {
      opt_in_consent_hint_id: optInConsentHintId,
      account: { email },
      variant: 'fused',
      code,
      q,
      custom_tags: customTags,
      category_tags: categoryTags,
      is_active: isActive,
      name: searchProfileName,
      campaign_layer: campaignLayerName,
      rc: registrationCampaign,
      return_to: returnTo,
      sponsored_mailing_opt_in_consent_hint_id:
        sponsoredMailingOptInConsentHintId,
      page_id: pageId
    }

    return client.post('/api/registrations.json', { json: body }).json()
  },

  latestOptInConsentHint() {
    return client.get('/api/latest_opt_in.json').json()
  },

  latestSponsoredMailingOptInConsentHint() {
    return client.get('/api/latest_sponsored_mailing_opt_in.json').json()
  }
}
