import { getScrollParent } from '@/util/get_scroll_parent'

export default {
  inserted: (el, binding, vnode) => {
    const scrollParent = getScrollParent(el)
    vnode.$scrollOberserver = new IntersectionObserver(
      entries => {
        entries.forEach(({ isIntersecting }) => {
          if (isIntersecting) {
            binding.value.handler()
          } else if (binding.value.offHandler) {
            binding.value.offHandler()
          }
        })
      },
      {
        rootMargin: binding.value.margin || '600px',
        threshold: binding.value.threshold || 0,
        root:
          scrollParent === document.documentElement ? undefined : scrollParent
      }
    )
    vnode.$scrollOberserver.observe(el)
  },
  unbind: (_el, _binding, vnode) => {
    vnode.$scrollOberserver && vnode.$scrollOberserver.disconnect()
  }
}
