// initial state constructor
export default () => ({
  account: {},
  bookmarks: [],
  categories: [],
  csrfParam: null,
  csrfToken: null,
  termSuggestions: [],
  headline: '',
  initialIntent: null,
  initialIntentData: null,
  isSearchVisible: false,
  isSearchSticky: false,
  job: null,
  jobPinned: null,
  jobLoadingId: null,
  jobMarket: null,
  links: {
    oauthOrigins: {}
  },
  listActionVisible: null,
  loginErrorNoticeLink: null,
  login: {
    currentPage: 'emailLogin',
    failure: null,
    passwordRequestEmail: null,
    emailRequestEmail: null,
    emailLoginEmail: null,
    registrationEmail: null
  },
  modalComponent: null,
  modalOpen: false,
  modalProps: null,
  optInConsentHint: null,
  sponsoredMailingOptInConsentHint: null,
  hasSponsoredMailingOptIn: false,
  pageType: null,
  results: [],
  resultsCount: 0,
  resultsLoading: true,
  resultsLoadingKey: null,
  resultsKey: '',
  resultsPagination: null,
  resultsJobSelected: null,
  resultsJobUserSelected: false,
  resultsSidebar: null,
  resultsMatchingProfile: null,
  resultsFenceUrl: null,
  searchProfile: {
    customTags: [],
    tagKeys: []
  },
  searchContext: null,
  searchContextEvaluated: false,
  sort: 'CURRENT_DESC',
  user: {
    email: null,
    status: null
  },
  usedSearchMapping: false,
  searchMappingPath: null,
  typeAheadUrl: null,
  consent: {
    google: 'UNKNOWN',
    doo: 'UNKNOWN'
  },
  featureFlags: {},
  referrer: null,
  mailSettings: []
})
