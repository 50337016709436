import client from './client'

export default {
  all() {
    return client.get('/api/bookmarks.json').json()
  },

  add(jobId) {
    return client.put(`/api/bookmarks/${jobId}.json`).json()
  },

  remove(jobId) {
    return client.delete(`/api/bookmarks/${jobId}.json`).json()
  }
}
