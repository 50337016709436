import client from './client'

export default {
  registerForEdudipEvent(eventId, userData) {
    const body = {
      ...userData
    }

    return client
      .post(`/api/event-registration/${eventId}.json`, { json: body })
      .json()
  },
  eventDates(eventId, jobMarket) {
    const body = {
      jobMarket
    }

    return client
      .post(`/api/event-dates/${eventId}.json`, { json: body })
      .json()
  }
}
