import client from './client'

export default {
  updateMailSettings(mailSettings) {
    return client
      .put('/api/mail_settings.json', {
        json: { mail_settings: mailSettings }
      })
      .json()
  },

  unsubscribeMail({ token }) {
    return client
      .put('/api/unsubscribe-mail.json', {
        json: { token }
      })
      .json()
  }
}
