import ky from 'ky'

const client = ky.extend({
  hooks: {
    beforeRequest: [
      request => {
        if (request.method !== 'GET') {
          request.headers.set('x-csrf-token', global.csrfToken)
        }
      }
    ]
  },
  timeout: 30000
})

function toUrl(input) {
  return new URL(input, window.location.origin)
}

// IE compatibility ugliness
// ky client relies on the url property of Request objects to be a valid URL
// sadly whatwg-fetch polyfill of Request objects is very naive and simply sets the provided string as the url property
// this wrapper makes sure noon accidentally breaks IE support by ("correctly") using the ky client with a relative path
export default {
  get(input, options) {
    return client.get(toUrl(input), options)
  },
  put(input, options) {
    return client.put(toUrl(input), options)
  },
  post(input, options) {
    return client.post(toUrl(input), options)
  },
  delete(input, options) {
    return client.delete(toUrl(input), options)
  }
}
