import client from './client'

export default {
  emailLogin({ email, returnTo }) {
    const body = {
      email,
      return_to: returnTo
    }

    return client.post('/api/sessions-email.json', { json: body }).json()
  },
  create({ email, password, returnTo }) {
    const body = {
      email,
      password,
      return_to: returnTo
    }

    return client.post('/api/sessions.json', { json: body }).json()
  },
  oauthPaths({
    intent,
    intentData,
    sponsoredMailingOptInConsentHintId,
    optInConsentHintId,
    customTags,
    categoryTags,
    q,
    code,
    name,
    isActive,
    rc
  }) {
    const body = {
      intent,
      q,
      code,
      name,
      is_active: isActive,
      intent_data: intentData,
      sponsored_mailing_opt_in_consent_hint_id:
        sponsoredMailingOptInConsentHintId,
      opt_in_consent_hint_id: optInConsentHintId,
      custom_tags: (customTags || []).join(','),
      category_tags: categoryTags,
      rc
    }

    return client.post('/api/oauth-paths.json', { json: body }).json()
  }
}
