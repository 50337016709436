import client from './client'

export default {
  all({ context, profile }, sort, page = 1) {
    let body = {
      variant: 'fused',
      page,
      sort
    }

    if (context) {
      body = { ...body, context }
    } else {
      body = {
        ...body,
        category_tags: profile.tagKeys,
        q: profile.customTags.join(',')
      }
    }

    return client.post('/api/jobs.json', { json: body }).json()
  },

  one(jobId, options) {
    return client
      .get(`/api/jobs/${jobId}.json`, {
        searchParams: { variant: 'fused', ...options }
      })
      .json()
  },

  mapSearch(query, currentSearchProfile) {
    const body = {
      q: query,
      current_search_profile: {
        custom_tags: currentSearchProfile.customTags,
        category_tags: currentSearchProfile.tagKeys
      }
    }

    return client.post('/api/search-mapping.json', { json: body }).json()
  }
}
