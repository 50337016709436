import without from 'lodash-es/without'
import omit from 'lodash-es/omit'

export default {
  listActionVisibleToggle(state, action) {
    const newAction = state.listActionVisible === action ? null : action
    state.listActionVisible = newAction
  },
  jobLoadingIdSet(state, id) {
    state.jobLoadingId = id
  },
  jobSet(state, job) {
    state.job = job
  },
  bookmarksSet(state, bookmarks) {
    state.bookmarks = bookmarks
  },
  pageTypeSet(state, pageType) {
    state.pageType = pageType
  },
  jobPinnedSet(state, jobPinned) {
    state.jobPinned = jobPinned
  },
  resultsCountSet(state, count) {
    state.resultsCount = count
  },
  resultsLoadingSet(state, loading) {
    state.resultsLoading = loading
  },
  modalOpen(state, { component, props = {} }) {
    state.modalComponent = component
    state.modalProps = props
    if (props.intent) {
      state.initialIntent = props.intent
      state.initialIntentData = props.intentData || null
    }
    state.modalOpen = true
  },
  modalClose(state) {
    state.modalComponent = null
    state.modalProps = null
    state.modalOpen = false
    state.initialIntent = null
    state.initialIntentData = null
  },
  searchVisibleSet(state, visible) {
    state.isSearchVisible = visible
  },
  searchStickyToggle(state) {
    state.isSearchSticky = !state.isSearchSticky
  },
  searchProfileAddTags(state, { customTags, tagKeys }) {
    state.searchProfile.tagKeys = [
      ...new Set([...(tagKeys || []), ...state.searchProfile.tagKeys])
    ]
    state.searchProfile.customTags = [
      ...new Set([...(customTags || []), ...state.searchProfile.customTags])
    ]
  },
  searchProfileRemoveCustomTag(state, tagKey) {
    state.searchProfile.customTags = without(
      state.searchProfile.customTags,
      tagKey
    )
  },
  searchProfileRemoveTag(state, tagKey) {
    state.searchProfile.tagKeys = without(state.searchProfile.tagKeys, tagKey)
  },
  searchProfileRemoveTags(state, tagKeys) {
    state.searchProfile.tagKeys = without(
      state.searchProfile.tagKeys,
      ...tagKeys
    )
  },
  searchProfileClear(state) {
    if (
      state.searchProfile.tagKeys.length === 0 &&
      state.searchProfile.customTags.length === 0
    ) {
      return
    }

    state.searchProfile.tagKeys = []
    state.searchProfile.customTags = []
  },
  searchProfileReplace(state, searchProfile) {
    state.searchProfile = omit(searchProfile, '_meta')
  },
  sortSet(state, { sort }) {
    state.sort = sort
  },
  sortSetWithoutCallback(state, { sort }) {
    state.sort = sort
  },
  resultsSet(
    state,
    {
      results,
      resultsPagination,
      resultsCount,
      resultsKey,
      append,
      sidebar,
      matchingProfile,
      fenceUrl,
      oauthOrigins,
      termSuggestions
    }
  ) {
    if (append) {
      state.results = state.results.concat(results)
    } else {
      state.results = results
    }
    state.resultsCount = resultsCount
    state.resultsKey = resultsKey
    state.resultsPagination = resultsPagination
    state.resultsSidebar = sidebar
    state.resultsMatchingProfile = matchingProfile
    state.resultsFenceUrl = fenceUrl
    state.links.oauthOrigins = oauthOrigins
    state.termSuggestions = termSuggestions || []
  },
  resultsLoadingKeySet(state, key) {
    state.resultsLoadingKey = key
  },
  resultsJobSelectedSet(state, jobId) {
    state.resultsJobSelected = jobId
  },
  resultsJobUserSelectedSet(state, bool) {
    state.resultsJobUserSelected = bool
  },
  resultsRestoreStateFromHistory(state, snapshot) {
    state.resultsJobSelected = snapshot.resultsJobSelected
    state.resultsJobUserSelected = snapshot.resultsJobUserSelected
    state.pageType = snapshot.pageType
    state.jobPinned = snapshot.jobPinned
  },
  userLoginSuccessSet(state, bool) {
    state.userLoginSuccess = bool
  },
  userSet(state, user) {
    state.user.email = user.email
    state.user.status = user.status
  },
  resetInitialIntent(state) {
    state.initialIntent = null
    state.initialIntentData = null
  },
  optInConsentHintSet(state, hint) {
    state.optInConsentHint = hint
  },
  sponsoredMailingOptInConsentHintSet(state, hint) {
    state.sponsoredMailingOptInConsentHint = hint
  },
  consentSet(state, { vendor, consent }) {
    state.consent[vendor] = consent
  },
  searchContextSet(state, context) {
    state.searchContext = context
  },
  searchContextEvaluatedSet(state, isEvaluated) {
    state.searchContextEvaluated = isEvaluated
  },
  usedSearchMappingSet(state, usedSearchMapping) {
    state.usedSearchMapping = usedSearchMapping
  },
  setReferrer(state, referrer) {
    state.referrer = referrer
  },
  setHasSponsoredMailingOptIn(state, hasSponsoredMailingOptIn) {
    state.hasSponsoredMailingOptIn = hasSponsoredMailingOptIn
  },
  setOauthOrigins(state, { google, facebook }) {
    state.links = { ...state.links, oauthOrigins: { google, facebook } }
  },
  resetLoginEmails(state) {
    ;[
      'passwordRequestEmail',
      'emailRequestEmail',
      'emailLoginEmail',
      'registrationEmail'
    ].forEach(email => {
      state.login[email] = null
    })
  },
  loginFailureReset(state) {
    state.login.failure = null
  },
  loginFailureSet(state, type) {
    state.login.failure = type
  },
  loginCurrentPageSet(state, currentPage) {
    state.login.currentPage = currentPage
  },
  loginEmailSet(state, { type, email }) {
    const key = `${type}Email`

    if (Object.keys(state.login).includes(key)) {
      state.login[key] = email
    }
  },
  setMailSetting(state, { type, value }) {
    const idx = state.mailSettings.findIndex(element => element.type === type)
    state.mailSettings.splice(idx, 1, { type, value })
  }
}
