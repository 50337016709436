import initialState from '@/vue/store/state'
import actions from '@/vue/store/actions'
import getters from '@/vue/store/getters'
import mutations from '@/vue/store/mutations'

const makeItSo = configs => {
  return store => {
    configs.forEach(({ updateAction, changeMutations, upToDateGetter }) => {
      store.subscribe((mutation, state) => {
        if (changeMutations.indexOf(mutation.type) !== -1) {
          const meta = (mutation.payload || {})._meta || {}
          store.dispatch(updateAction, {
            _meta: { triggeredBy: mutation.type, ...meta }
          })
        }
      })
    })
  }
}

export default {
  plugins: [
    makeItSo([
      {
        changeMutations: [
          'resultsJobSelectedSet',
          'resultsRestoreStateFromHistory'
        ],
        updateAction: 'jobUpdate'
      },
      {
        changeMutations: [
          'searchProfileAddTags',
          'searchProfileRemoveCustomTag',
          'searchProfileRemoveTag',
          'searchProfileRemoveTags',
          'searchProfileClear',
          'searchProfileReplace',
          'sortSet'
        ],
        updateAction: 'resultsUpdate'
      },
      {
        changeMutations: ['resultsSet', 'jobSet'],
        updateAction: 'trackGoogleTagManagerEvent'
      }
    ])
  ],

  state: initialState,
  mutations,
  actions,
  getters
}
