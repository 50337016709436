import client from './client'

export default {
  save({ tagKeys, customTags }) {
    const body = {
      category_tags: tagKeys,
      custom_tags: (customTags || []).join(','),
      variant: 'fused'
    }

    return client.post('/api/search-profiles.json', { json: body }).json()
  },

  updateFrequency({ searchProfileId, frequency }) {
    const body = {
      frequency,
      variant: 'fused'
    }

    return client
      .put(`/api/search-profile/${searchProfileId}.json`, { json: body })
      .json()
  },

  code({ categoryTags }) {
    return client
      .post('/api/search-profiles-code.json', {
        json: { category_tags: categoryTags }
      })
      .json()
  },

  deactivate({ token }) {
    return client
      .put('/api/deactivate-search-profile.json', { json: { token } })
      .json()
  },

  changeSearchProfileFrequencyFromMailToken({ token, frequency }) {
    return client
      .put('/api/change-search-profile-frequency', {
        json: { token, frequency }
      })
      .json()
  }
}
