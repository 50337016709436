<template lang="html">
  <svg class="icon" :class="className" xmlns="http://www.w3.org/2000/svg">
    <use :xlink:href="`#icons-${iconName}`" />
  </svg>
</template>

<script>
export default {
  props: {
    className: {
      type: String,
      default: ''
    },
    iconName: {
      type: String,
      required: true
    }
  }
}
</script>
