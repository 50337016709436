import flatMap from 'lodash-es/flatMap'
import fromPairs from 'lodash-es/fromPairs'
import _get from 'lodash-es/get'

const LIST_ACTION_FILTER = 'filter'
const LIST_ACTION_SORT = 'sort'

const getResultsKey = (searchProfile, sort) => {
  return `${searchProfile.tagKeys.join('-')}.${searchProfile.customTags.join(
    '-'
  )}.${sort}`
}

export default {
  isFilterVisible: state => state.listActionVisible === LIST_ACTION_FILTER,
  isSortVisible: state => state.listActionVisible === LIST_ACTION_SORT,

  isPageTypeJobList: state => state.pageType === 'JOBLIST',

  isBookmarked:
    (_state, { bookmarksMap }) =>
    jobId => {
      return bookmarksMap[jobId]
    },
  bookmarksMap: ({ bookmarks }) => {
    return bookmarks.reduce((acc, val) => {
      acc[val] = true
      return acc
    }, {})
  },
  resultsNext(
    _state,
    { isResultsNextAvailable, resultsJobSelectedIndex, resultsAsDisplayed }
  ) {
    if (isResultsNextAvailable) {
      return resultsAsDisplayed[resultsJobSelectedIndex + 1]
    } else {
      return null
    }
  },
  resultsPrev(
    _state,
    { isResultsPrevAvailable, resultsJobSelectedIndex, resultsAsDisplayed }
  ) {
    if (isResultsPrevAvailable) {
      return resultsAsDisplayed[resultsJobSelectedIndex - 1]
    } else {
      return null
    }
  },
  resultsAsDisplayed({ results, jobPinned }) {
    if (jobPinned) {
      return [jobPinned, ...results.filter(job => job.id !== jobPinned.id)]
    } else {
      return results
    }
  },
  isJobExpired({ job }) {
    if (!job || !job.data) {
      return false
    } else {
      return job.data.expired
    }
  },
  isJobOffline({ job }) {
    return !job || !job.data ? false : job.data.offline
  },
  jobTemplateHeadlineColor({ job }) {
    const color = _get(job, 'data.adeleMedia.headlineColor')
    if (color && color !== '' && /^#([0-9A-F]{3}){1,2}$/i.test(color)) {
      return color
    } else {
      return null
    }
  },
  jobTemplateBackgroundImage({ job }) {
    const image = _get(job, 'data.adeleMedia.backgroundImage')
    if (image && image !== '') {
      return image
    } else {
      return null
    }
  },
  jobTemplateStyle(
    { job },
    { jobTemplateBackgroundImage, jobTemplateHeadlineColor }
  ) {
    const templateStyle = { cssClass: null, vars: {} }

    if (_get(job, 'data.template') !== 'stellenanzeigeonlineadliquidplus') {
      return templateStyle
    }

    if (jobTemplateHeadlineColor) {
      templateStyle.cssClass = 'fsd-job-template__plus--with-color'
      templateStyle.vars = {
        ...templateStyle.vars,
        '--template-color': jobTemplateHeadlineColor
      }
    }
    if (jobTemplateBackgroundImage) {
      // override --with-color class without loosing --template-color css var
      templateStyle.cssClass = 'fsd-job-template__plus--with-picture'
      templateStyle.vars = {
        ...templateStyle.vars,
        '--template-bg-image': `url(${jobTemplateBackgroundImage})`
      }
    }

    return templateStyle
  },
  isUserLoggedIn({ user }) {
    if (!user) {
      return false
    } else {
      return user.status === 'logged_in'
    }
  },
  isUserKnown({ user }) {
    return !!user && user.status === ('known' || 'logged_in')
  },
  isResultsNextAvailable(
    _state,
    { resultsAsDisplayed, resultsJobSelectedIndex }
  ) {
    return resultsJobSelectedIndex + 1 !== resultsAsDisplayed.length
  },
  isResultsPrevAvailable(_state, { resultsJobSelectedIndex }) {
    return resultsJobSelectedIndex > 0
  },
  resultsJobSelectedIndex({ resultsJobSelected }, { resultsAsDisplayed }) {
    return resultsAsDisplayed.findIndex(job => job.id === resultsJobSelected)
  },
  flatTagMap({ categories }) {
    return fromPairs(
      flatMap(categories, category => {
        return flatMap(category.grouped_category_tags, group => {
          return group.group_tags.map(tag => [tag.key, tag])
        })
      })
    )
  },
  checkedCategoryTagsMap(_state, { checkedCategoryTags }) {
    return (
      (checkedCategoryTags &&
        fromPairs(checkedCategoryTags.map(tag => [tag.key, tag]))) ||
      {}
    )
  },
  checkedCategoryTags({ searchProfile }, { flatTagMap }) {
    return (
      (searchProfile &&
        searchProfile.tagKeys.map(tagKey => flatTagMap[tagKey])) ||
      []
    )
  },
  desiredResultsKey({ searchProfile, sort }, { checked }) {
    return searchProfile && getResultsKey(searchProfile, sort)
  },
  resultsUpToDate: ({ resultsKey }, { desiredResultsKey }) =>
    desiredResultsKey === resultsKey,
  jobUpToDate: ({ job, resultsJobSelected }) =>
    !resultsJobSelected || (job && job.id === resultsJobSelected),
  isSearchProfileEmpty: ({ searchProfile }) => {
    return (
      searchProfile &&
      searchProfile.tagKeys.length === 0 &&
      searchProfile.customTags.length === 0
    )
  },
  historyStateSnapshot: ({
    resultsJobSelected,
    resultsJobUserSelected,
    searchProfile,
    sort,
    pageType,
    jobPinned
  }) => {
    return {
      resultsJobSelected,
      resultsJobUserSelected,
      searchProfile,
      sort,
      pageType,
      jobPinned
    }
  },
  isSearchEmpty: ({ sort }, { isSearchProfileEmpty, defaultSort }) => {
    return sort === defaultSort && isSearchProfileEmpty
  },
  defaultSort: ({ searchProfile }) => {
    if (
      searchProfile &&
      searchProfile.customTags &&
      searchProfile.customTags.length > 0
    ) {
      return 'STANDARD'
    } else {
      return 'CURRENT_DESC'
    }
  },
  mailSettings: state => {
    return state.mailSettings
  }
}
