import debounce from 'lodash-es/debounce'
import get from 'lodash-es/get'
import isEqual from 'lodash-es/isEqual'
import BookmarksApi from '@/vue/api/bookmarks'
import JobsApi from '@/vue/api/jobs'
import SearchProfilesApi from '@/vue/api/search_profiles'
import SessionsApi from '@/vue/api/sessions'
import RegistrationsApi from '@/vue/api/registrations'
import PasswordRequestsApi from '@/vue/api/password_requests'
import EdudipEventApi from '@/vue/api/edudip_event'
import { getCurrentSearchContext } from '@/util/search_context'
import { registerGoogleTagManagerEvent } from '@/gtm_tracking/events'
import MailSettings from '@/vue/api/mail_settings'

const getResultsKey = (searchProfile, sort) => {
  return `${searchProfile.tagKeys.join('-')}.${searchProfile.customTags.join(
    '-'
  )}.${sort}`
}

const LIST_ACTION_FILTER = 'filter'
const LIST_ACTION_SORT = 'sort'

// map intent to store action
const INTENTS = {
  saveSearchProfile: 'saveSearchProfile',
  toggleBookmark: 'bookmarksToggle'
}

export default {
  bookmarksToggle({ state, commit, dispatch }, jobId) {
    const id = Number.parseInt(jobId)
    const idx = state.bookmarks.indexOf(id)
    if (idx === -1) {
      return BookmarksApi.add(jobId).then(data => {
        commit('bookmarksSet', [...state.bookmarks, id])
        EventBus.trigger('bookmarks:added', { jobId })
        EventBus.trigger('bookmarks:count', data.bookmarkCount)
        EventBus.trigger('snackbar:message', {
          body: data.snackbar,
          handlers: []
        })
      })
    } else {
      return BookmarksApi.remove(jobId).then(data => {
        const newBookmarks = [...state.bookmarks]
        newBookmarks.splice(idx, 1)
        commit('bookmarksSet', newBookmarks)
        EventBus.trigger('bookmarks:removed', { jobId })
        EventBus.trigger('bookmarks:count', data.bookmarkCount)
        EventBus.trigger('snackbar:message', {
          body: data.snackbar,
          handlers: [
            {
              selector: '.js-snackbar__button',
              eventName: 'click',
              handler: () => dispatch('bookmarksToggle', jobId)
            }
          ]
        })
      })
    }
  },
  bookmarksGet({ commit }) {
    BookmarksApi.all().then(bookmarks => {
      commit('bookmarksSet', bookmarks)
    })
  },

  updateNewsletterFrequency(_context, { searchProfileId, frequency }) {
    return SearchProfilesApi.updateFrequency({
      searchProfileId,
      frequency
    })
  },

  saveSearchProfile({ state }) {
    SearchProfilesApi.save(state.searchProfile).then(data => {
      EventBus.trigger('snackbar:message', {
        body: data.snackbar,
        handlers: []
      })
    })
  },

  resultsUpdate({ state, commit, getters, dispatch }, { _meta }) {
    const key = getResultsKey(state.searchProfile, state.sort)

    if (!getters.resultsUpToDate && state.resultsLoadingKey !== key) {
      dispatch('forceResultUpdate', { _meta })
    }
  },
  forceResultUpdate({ state, commit, dispatch }, { _meta }) {
    const key = getResultsKey(state.searchProfile, state.sort)
    const sort = _meta.triggeredBy === 'sortSet' ? state.sort : null

    commit('resultsLoadingKeySet', key)
    commit('resultsLoadingSet', true)
    commit('resultsJobSelectedSet', null)
    commit('jobSet', null)
    return JobsApi.all({ profile: state.searchProfile }, sort).then(data => {
      dispatch('resultsAfterUpdate', { data, key, _meta })
    })
  },
  // TODO; 1390 restoreStateFromHistory vs "normal" resultsAfterUpdate
  resultsAfterUpdate(
    { state, commit, getters, dispatch },
    { data, key, append, _meta = {} }
  ) {
    if (key === getters.desiredResultsKey) {
      commit('pageTypeSet', 'JOBLIST')

      commit('resultsSet', {
        results: data.results,
        resultsCount: data.count,
        resultsKey: key,
        resultsPagination: data.pagination,
        append,
        sidebar: data.sidebar,
        matchingProfile: data.matchingProfile,
        fenceUrl: data.fenceUrl,
        oauthOrigins: data.links.oauthOrigins,
        termSuggestions: data.termSuggestions
      })
      commit('searchContextSet', data.searchContext)
      commit('resultsLoadingSet', false)

      // run mutation without callback
      commit('sortSetWithoutCallback', { sort: data.sort })

      // prevent replacement of pageType and jobPinned when resultsAfterUpdate was triggered by restoreStateFromHistory action
      // this is necessary since resultsAfterUpdate is simultaneously
      // (a) our point of reference for switching between page types ( JOB vs JOBLIST )
      // and (b) used to restore state form history to get back from page type JOBLIST to JOB
      if (_meta.triggeredBy !== 'restoreStateFromHistory') {
        commit('jobPinnedSet', null)
      }

      if (!append) {
        dispatch('resultsSelectFirst')
      }
      // TODO: use Vue Router instead
      // only push history on href or sorting change
      if (
        (document.location.href.replace(document.location.origin, '') !==
          data.url ||
          _meta.triggeredBy === 'sortSet') &&
        // do not push history if we are appending results or if update was triggered by restoring from history
        _meta.triggeredBy !== 'restoreStateFromHistory' &&
        !append
      ) {
        dispatch('pushHistoryState', {
          title: get(data, 'meta.meta_tags.title'),
          url: data.url
        })
      }
      EventBus.trigger('tracking:pageload', {
        pixelsConfig: data.webtrekk,
        referrer: state.referrer
      })
    }
  },

  jobUpdate({ state, commit, getters, dispatch }) {
    const jobId = state.resultsJobSelected
    if (jobId && !getters.jobUpToDate && state.jobLoadingId !== jobId) {
      commit('jobLoadingIdSet', jobId)
      commit('jobSet', null)
      dispatch('debouncedJobUpdate', jobId)
    }
  },

  debouncedJobUpdate: debounce(
    ({ dispatch, state }, jobId) => {
      // unless user selected a job_preview we treat the job request as the initial job_preview request
      return JobsApi.one(jobId, {
        initial: !state.resultsJobUserSelected
      }).then(data => {
        dispatch('jobAfterUpdate', { data, jobId })
      })
    },
    300,
    { leading: true, trailing: true }
  ),

  jobAfterUpdate({ state, commit, getters }, { data, jobId }) {
    if (jobId === state.resultsJobSelected) {
      if (state.resultsJobUserSelected) {
        commit('pageTypeSet', 'JOB')
      }
      commit('jobLoadingIdSet', null)
      commit('jobSet', { ...data, id: jobId })
      EventBus.trigger('tracking:pageload', {
        pixelsConfig: data.webtrekk,
        referrer: state.referrer
      })
    }
  },

  resultsPaginationNext({ state, commit, getters, dispatch }) {
    if (state.resultsPagination && !state.resultsPagination.isLast) {
      const key = getResultsKey(state.searchProfile, state.sort)

      return JobsApi.all(
        { profile: state.searchProfile },
        state.sort,
        state.resultsPagination.current + 1
      ).then(data => {
        dispatch('resultsAfterUpdate', { data, key, append: true })
      })
    }
  },
  resultsSelectNext({ state, getters, dispatch, commit }) {
    if (getters.resultsNext !== null) {
      const nextJob = getters.resultsNext
      dispatch('resultsJobUserSelect', {
        jobId: nextJob.id,
        fullPath: nextJob.full_path,
        title: nextJob.metaTitle
      })
    }
  },
  resultsSelectPrev({ state, getters, dispatch, commit }) {
    if (getters.resultsPrev !== null) {
      const prevJob = getters.resultsPrev
      dispatch('resultsJobUserSelect', {
        jobId: prevJob.id,
        fullPath: prevJob.full_path,
        title: prevJob.metaTitle
      })
    }
  },

  filterToggle({ commit }) {
    commit('listActionVisibleToggle', LIST_ACTION_FILTER)
  },

  sortToggle({ commit }) {
    commit('listActionVisibleToggle', LIST_ACTION_SORT)
  },

  resultsSelectFirst({ state, commit }) {
    commit('resultsJobUserSelectedSet', false)
    if (state.pageType === 'JOB' && state.jobPinned) {
      commit('resultsJobSelectedSet', state.jobPinned.id)
    } else if (state.results && state.results.length > 0) {
      commit('resultsJobSelectedSet', state.results[0].id)
    } else if (state.results && state.results.length === 0) {
      commit('resultsJobSelectedSet', null)
    }
  },
  resultsJobUserSelect({ dispatch, commit }, { jobId, fullPath, title }) {
    commit('resultsJobUserSelectedSet', true)
    commit('resultsJobSelectedSet', jobId)
    dispatch('pushHistoryState', {
      title: title || '',
      url: fullPath,
      persistContext: true
    })
  },

  restoreStateFromHistory({ commit, dispatch, state }, popstateEventState) {
    // ensure that following actions triggered via makeItSo plugin preserve this triggeredBy
    // by explicitly passing it to mutations in _meta object
    const meta = { triggeredBy: 'restoreStateFromHistory' }

    commit('resultsRestoreStateFromHistory', {
      ...popstateEventState,
      _meta: meta
    })
    if (!isEqual(popstateEventState.searchProfile, state.searchProfile)) {
      commit('searchProfileReplace', {
        ...popstateEventState.searchProfile,
        _meta: meta
      })
    }
    if (popstateEventState.sort !== state.sort) {
      commit('sortSet', { sort: popstateEventState.sort, _meta: meta })
    }
    if (popstateEventState.title) {
      document.title = popstateEventState.title
    }
  },
  pushHistoryState({ state, getters, dispatch }, options = {}) {
    if (options.title) {
      document.title = options.title
    }
    let url = options.url
    if (url && options.persistContext && state.searchContext) {
      url += `#${state.searchContext}`
    }
    dispatch('setReferrer', document.URL)
    window.history.pushState(
      { title: options.title, ...getters.historyStateSnapshot },
      '',
      url
    )
  },
  replaceInitialHistoryState({ state, getters, dispatch }, options = {}) {
    if (options.title) {
      document.title = options.title
    }
    // Edge implementation of history.replaceState will treat and undefined url as a string
    // and change url to <route>/undefined
    let url = options.url || null
    if (url && options.persistContext && state.searchContext) {
      url += `#${state.searchContext}`
    }
    dispatch('setReferrer', document.referrer)
    window.history.replaceState(
      { title: document.title, ...getters.historyStateSnapshot },
      '',
      url
    )
  },

  searchReset({ commit }) {
    commit('searchProfileClear')
    commit('sortSet', { sort: this.getters.defaultSort })
  },

  mapSearch({ state, commit }, query) {
    return JobsApi.mapSearch(query, state.searchProfile).then(
      newSearchProfile => {
        commit('searchProfileAddTags', {
          tagKeys: newSearchProfile.tagKeys,
          customTags: newSearchProfile.customTags
        })
        // Record interaction with search so user might get to see the Rating component
        commit('usedSearchMappingSet', true)
      }
    )
  },

  userLogin(_ctx, { email, password, returnTo }) {
    return SessionsApi.create({ email, password, returnTo })
  },

  userEmailLogin(_ctx, { email, returnTo }) {
    return SessionsApi.emailLogin({ email, returnTo })
  },

  oauthPaths(
    { commit },
    {
      intent,
      intentData,
      sponsoredMailingOptInConsentHintId,
      optInConsentHintId,
      customTags,
      categoryTags,
      q,
      code,
      name,
      isActive,
      rc
    }
  ) {
    return SessionsApi.oauthPaths({
      intent,
      intentData,
      sponsoredMailingOptInConsentHintId,
      optInConsentHintId,
      customTags,
      categoryTags,
      q,
      code,
      name,
      isActive,
      rc
    })
      .then(response => {
        commit('setOauthOrigins', {
          google: response.google,
          facebook: response.facebook
        })
      })
      .catch(_response => {
        commit('setOauthOrigins', { google: null, facebook: null })
      })
  },

  registerUser(
    { state },
    {
      email,
      code,
      q,
      customTags,
      categoryTags,
      isActive,
      hasSponsoredMailingOptIn,
      searchProfileName,
      campaignLayerName,
      registrationCampaign,
      returnTo,
      name,
      pageId
    }
  ) {
    const sponsoredMailingOptInConsentHint = hasSponsoredMailingOptIn
      ? {
          sponsoredMailingOptInConsentHintId:
            state.sponsoredMailingOptInConsentHint.id
        }
      : {}
    return RegistrationsApi.create({
      optInConsentHintId: state.optInConsentHint.id,
      email,
      code,
      q,
      customTags,
      categoryTags,
      isActive,
      searchProfileName,
      campaignLayerName,
      registrationCampaign,
      returnTo,
      name,
      pageId,
      ...sponsoredMailingOptInConsentHint
    })
  },

  requestPasswordReset({ state }, { email }) {
    return PasswordRequestsApi.create({ email })
  },

  fullfillInitialIntent({ state, commit, dispatch }) {
    const action = INTENTS[state.initialIntent]
    if (state.initialIntent && action) {
      dispatch(action, state.initialIntentData)
    }
    commit('resetInitialIntent')
  },

  ensureOptInConsentHintPresence({ state, commit }) {
    if (!state.optInConsentHint) {
      RegistrationsApi.latestOptInConsentHint().then(hint => {
        commit('optInConsentHintSet', hint)
      })
    }
  },

  ensureSponsoredMailingConsentHintPresence({ state, commit }) {
    if (!state.sponsoredMailingOptInConsentHint) {
      RegistrationsApi.latestSponsoredMailingOptInConsentHint().then(hint => {
        commit('sponsoredMailingOptInConsentHintSet', hint)
      })
    }
  },

  evaluateSearchContext({ state, dispatch, commit }) {
    const contextCode = getCurrentSearchContext()
    if (contextCode) {
      commit('searchContextSet', contextCode)
      commit('resultsLoadingSet', true)
      if (!state.jobPinned && state.job) {
        commit('jobPinnedSet', state.job.data)
      }
      return JobsApi.all({ context: contextCode }, state.sort)
        .then(data => {
          commit('resultsSet', {
            results: data.results,
            resultsCount: data.count,
            resultsKey: getResultsKey(data.searchProfile, state.sort),
            resultsPagination: data.pagination,
            sidebar: data.sidebar,
            matchingProfile: data.matchingProfile,
            fenceUrl: data.fenceUrl,
            oauthOrigins: data.links.oauthOrigins,
            termSuggestions: data.termSuggestions
          })
          commit('searchProfileReplace', data.searchProfile)
        })
        .catch(_response => {
          commit('searchContextSet', null)
        })
        .finally(() => {
          commit('searchContextEvaluatedSet', true)
          commit('resultsLoadingSet', false)
          dispatch('replaceInitialHistoryState')
          // added by views/shared/_ssr_vue_skeleton_script to enable skeleton for ssr
          document.body.classList.remove('fsd-layout--skeleton')
        })
    } else {
      commit('searchContextEvaluatedSet', true)
      dispatch('replaceInitialHistoryState')
    }
  },

  trackGoogleTagManagerEvent({ state }, { action, data, _meta }) {
    const triggeredBy = get(_meta, 'triggeredBy') || action
    if (!triggeredBy) {
      return
    }

    let eventArgs
    if (triggeredBy === 'resultsSet' && state.pageType === 'JOBLIST') {
      eventArgs = ['listingPage', { jobIds: state.results.map(job => job.id) }]
    } else if (
      triggeredBy === 'jobSet' &&
      state.pageType === 'JOB' &&
      state.job
    ) {
      eventArgs = ['productPage', { job: state.job.data }]
    } else if (triggeredBy === 'applicationIntent' && state.job) {
      const email = state.account.email || state.user.email
      eventArgs = ['transactionPage', { email, job: state.job.data }]
    }

    if (eventArgs) {
      // as of now we only (manually) add criteo related events to GTM
      // therefore we just add the criteo feature flag as a dry run toggle for all events
      eventArgs.push({ dryRun: !state.featureFlags.criteo })
      registerGoogleTagManagerEvent(...eventArgs)
    }
  },

  registerConsentListeners({ commit }) {
    EventBus.on('consent:addGOOGLE', () => {
      commit('consentSet', { vendor: 'google', consent: 'GRANTED' })
    })
    EventBus.on('consent:removeGOOGLE', () => {
      commit('consentSet', { vendor: 'google', consent: 'DENIED' })
    })
  },

  generateSearchProfileCode(_context, { categoryTags }) {
    return SearchProfilesApi.code({ categoryTags })
  },

  setReferrer: ({ commit }, referrer) => commit('setReferrer', referrer),

  registerForEdudipEvent(_context, { eventId, userData }) {
    return EdudipEventApi.registerForEdudipEvent(eventId, userData)
  },

  edudipEventDates(_context, { eventId, jobMarket }) {
    return EdudipEventApi.eventDates(eventId, jobMarket)
  },

  redirect(_context, returnTo) {
    if (window) {
      window.location.href = returnTo
    }
  },

  setHasSponsoredMailingOptIn: ({ commit }, hasSponsoredMailingOptIn) =>
    commit('setHasSponsoredMailingOptIn', hasSponsoredMailingOptIn),

  resetLoginEmails: ({ commit }) => commit('resetLoginEmails'),
  loginFailureReset: ({ commit }) => commit('loginFailureReset'),
  loginFailureSet: ({ commit }, type) => commit('loginFailureSet', type),
  loginCurrentPageSet: ({ commit }, value) =>
    commit('loginCurrentPageSet', value),
  loginEmailSet: ({ commit }, { type, email }) =>
    commit('loginEmailSet', { type, email }),
  deactivateSearchProfileFromMailToken: (_context, { token }) =>
    SearchProfilesApi.deactivate({ token }),
  changeSearchProfileFrequencyFromMailToken: (_store, { token, frequency }) =>
    SearchProfilesApi.changeSearchProfileFrequencyFromMailToken({
      token,
      frequency
    }),
  updateMailSetting({ commit, getters }, { name, active }) {
    commit('setMailSetting', { type: name, value: active })
    MailSettings.updateMailSettings(getters.mailSettings)
  },
  disableAllMailSettings({ commit, getters }) {
    getters.mailSettings.forEach(setting =>
      commit('setMailSetting', { ...setting, value: false })
    )
    MailSettings.updateMailSettings(getters.mailSettings)
  },
  unsubscribeMail: (_context, { token }) =>
    MailSettings.unsubscribeMail({ token })
}
